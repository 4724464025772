import { getGql, schToMut } from "../_"

type Variables = Partial<Record<string, any>>

export default (a: NuxtAxiosInstance) => {
  const gql = getGql(a)

  return {
    getGrants: () => gql(
      `
        query {
          listMyZayavkagant {
            items {
              id
              creationTime
              updateTime
              author
              state
              category
              companyame
              region
              email
              phone
              sitelink
              cennost
              napravlenyiemer
              naimenovaniyeproectnoyactivnosty
              tsel
              zadachy
              datanachala
              datacontsa
              statusmaschtab
              geography
              format
              opisaniye
              obosnovanye
              rezultatopisaniye
              kolichestvouchastnikov
              categoryuchastnikov
              unikalnost
              organy
              rukovoditel
              projectteam
              opyt
              presentation {
                path
              }
              social
              docs {
                path
              }
              doc2 {
                path
              }
              doc21 {
                path
              }
              doc22 {
                path
              }
              doc23 {
                path
              }
              projectevents
              zakupki
              zakupkiaktivov
              oplatatruda
              komandirovki
              doc3 {
                path
              }
              doc4 {
                path
              }
              doc5 {
                path
              }
              doc6 {
                path
              }
              doc7 {
                path
              }
            }
          }
        }
      `,
    ),

    createGrant: (variables: Variables) => gql(schToMut(
      `
        createZayavkagant(
          category: String!
          companyame: String!
          region: String!
          email: String!
          phone: String!
          sitelink: String
          cennost: [String!]
          napravlenyiemer: [String!]
          naimenovaniyeproectnoyactivnosty: String
          tsel: String
          zadachy: String
          datanachala: DateTime
          datacontsa: DateTime
          statusmaschtab: String
          geography: String
          format: String
          opisaniye: String
          obosnovanye: String
          rezultatopisaniye: String
          kolichestvouchastnikov: Float
          categoryuchastnikov: String
          unikalnost: String
          organy: String
          rukovoditel: String
          projectteam: String
          opyt: String

          "Upload"
          presentation: UploadPath

          social: String

          "Upload"
          docs: UploadPath

          "Upload"
          doc2: UploadPath

          "Upload"
          doc21: UploadPath

          "Upload"
          doc22: UploadPath

          "Upload"
          doc23: UploadPath

          projectevents: [AnyObject!]
          zakupki: [AnyObject!]
          zakupkiaktivov: [AnyObject!]
          oplatatruda: [AnyObject!]
          komandirovki: [AnyObject!]

          "Upload"
          doc3: UploadPath

          "Upload"
          doc4: UploadPath

          "Upload"
          doc5: UploadPath

          "Upload"
          doc6: UploadPath

          "Upload"
          doc7: UploadPath
        ): Zayavkagant!
      `,
    ), variables),

    updateGrant: (variables: Variables) => gql(schToMut(
      `
        updateZayavkagant(
          "Zayavkagant"
          id: UUID!

          category: String!
          companyame: String!
          region: String!
          email: String!
          phone: String!
          sitelink: String
          cennost: [String!]
          napravlenyiemer: [String!]
          naimenovaniyeproectnoyactivnosty: String
          tsel: String
          zadachy: String
          datanachala: DateTime
          datacontsa: DateTime
          statusmaschtab: String
          geography: String
          format: String
          opisaniye: String
          obosnovanye: String
          rezultatopisaniye: String
          kolichestvouchastnikov: Float
          categoryuchastnikov: String
          unikalnost: String
          organy: String
          rukovoditel: String
          projectteam: String
          opyt: String

          "Upload"
          presentation: UploadPath

          social: String

          "Upload"
          docs: UploadPath

          "Upload"
          doc2: UploadPath

          "Upload"
          doc21: UploadPath

          "Upload"
          doc22: UploadPath

          "Upload"
          doc23: UploadPath

          projectevents: [AnyObject!]
          zakupki: [AnyObject!]
          zakupkiaktivov: [AnyObject!]
          oplatatruda: [AnyObject!]
          komandirovki: [AnyObject!]

          "Upload"
          doc3: UploadPath

          "Upload"
          doc4: UploadPath

          "Upload"
          doc5: UploadPath

          "Upload"
          doc6: UploadPath

          "Upload"
          doc7: UploadPath
        ): Zayavkagant!
      `,
    ), variables),
  }
}
