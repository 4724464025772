export const actions = {
  async nuxtServerInit ({ dispatch }) {
    await Promise.all([
      dispatch("app/FETCH_EVENT"),
      dispatch("app/FETCH_MENU"),
      // dispatch("live/FETCH_HALLS"),
      dispatch("user/fetchTeams"),
    ])
  },
}
