// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y\\+3yt000{background:var(--color-bg);padding:max(1.76rem,min(2.6vw,3.13rem)) 0}.y\\+3yt000 ._4oWuFixV,.dVFFnm\\+z{justify-content:center}.dVFFnm\\+z{align-items:center;display:flex;flex-wrap:wrap;gap:max(2.25rem,min(3.33vw,4rem)) max(4.5rem,min(6.67vw,8rem))}.HLZqQ\\+sL>*+*{margin-top:max(1.13rem,min(1.67vw,2rem))}.jjT0DghW{align-items:center;color:var(--color-primary);display:flex;flex-shrink:0;justify-content:center;width:max(2rem,min(2.5vw,3rem))}.jjT0DghW svg{color:inherit;height:auto;width:100%}.jjT0DghW svg *{fill:currentColor}.lTSA9jjs{font-size:.88rem;line-height:1.2}@media(max-width:667.98px){.lTSA9jjs{text-align:center}}.G\\+yhWwPn{display:flex;flex-wrap:wrap;gap:max(.84rem,min(1.25vw,1.5rem)) max(2.25rem,min(3.33vw,4rem))}@media(max-width:667.98px){.G\\+yhWwPn{justify-content:center;text-align:center}}.-y220f3w{display:flex;flex-direction:column;justify-content:space-between}.vJOw8\\+jF{font-size:.75rem;line-height:1.14;margin-bottom:max(.28rem,min(.42vw,.5rem));opacity:.6}.ZrsBWWdM{font-size:1.13rem}@media(hover:hover){.ZrsBWWdM a:hover{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.ZrsBWWdM a:active{-webkit-text-decoration:underline;text-decoration:underline}}.FruoAxHP{align-items:center;display:flex;flex-wrap:wrap;gap:max(.84rem,min(1.25vw,1.5rem)) max(2.25rem,min(3.33vw,4rem))}@media(max-width:667.98px){.FruoAxHP{justify-content:center;text-align:center}}.BjN54d0D{font-size:.75rem;line-height:1.14}@media(hover:hover){.BjN54d0D:hover{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.BjN54d0D:active{-webkit-text-decoration:underline;text-decoration:underline}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "y+3yt000",
	"logos": "_4oWuFixV",
	"inner": "dVFFnm+z",
	"innerCenter": "HLZqQ+sL",
	"logo": "jjT0DghW",
	"text": "lTSA9jjs",
	"contacts": "G+yhWwPn",
	"contact": "-y220f3w",
	"contactTitle": "vJOw8+jF",
	"contactValue": "ZrsBWWdM",
	"links": "FruoAxHP",
	"link": "BjN54d0D"
};
module.exports = ___CSS_LOADER_EXPORT___;
