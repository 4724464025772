import { wrap, wrapGql, getGql } from "../_"

type UpdateUserData = any // TODO
type CreateUserData = any // TODO
type CreateUserQuickData = any // TODO

interface SendTokenData { email: string, status?: string }

const pfx = "/user"

export default (a: NuxtAxiosInstance) => {
  const gql = getGql(a)

  return {
    getFavoriteUsers: (cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/favorite`,
        { ...cfg })),

    addFavoriteUser: (userId: string, cfg?: AxiosConfig) => wrap(
      a.$post(`${pfx}/${userId}/favorite`, {},
        { ...cfg })),

    removeFavoriteUser: (userId: string, cfg?: AxiosConfig) => wrap(
      a.$delete(`${pfx}/${userId}/favorite`,
        { ...cfg })),

    getUsers: (cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}`,
        { cache: true, ...cfg })),

    updateUser: (data: UpdateUserData, cfg?: AxiosConfig) => wrap(
      a.$patch(`${pfx}`, data,
        { ...cfg })),

    getUser: (cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/my`,
        { ...cfg })),

    getCountries: (cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/countries`,
        { cache: true, ...cfg })),

    getColleague: (userId: string, cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/colleague/${userId}`,
        { ...cfg })),

    updateColleague: (userId: string, data: UpdateUserData, cfg?: AxiosConfig) =>
      wrap(
        a.$patch(`${pfx}/colleague/${userId}`, data,
          { ...cfg })),

    createColleague: (data: CreateUserData, cfg?: AxiosConfig) => wrap(
      a.$post(`${pfx}/colleague`, data,
        { ...cfg })),

    createUser: (data: CreateUserData, cfg?: AxiosConfig) => wrap(
      a.$post(`${pfx}`, data,
        { ...cfg })),

    sendToken: (data: SendTokenData, cfg?: AxiosConfig) => wrap(
      a.$post(`${pfx}/registration-token`, data,
        { ...cfg })),

    getTokenData: (token: string, cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/registration-token/${token}`,
        { ...cfg })),

    createUserQuick: (data: CreateUserQuickData, cfg?: AxiosConfig) => wrap(
      a.$post(`${pfx}/quick-registration`, data,
        { ...cfg })),

    getPreviousData: (token: string, cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/previous-user/${token}`,
        { ...cfg })),

    getA5KUsers: (cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/a5k`,
        { cache: true, ...cfg })),

    getRecommendedUsers: (cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/recommended`,
        { ...cfg })),

    getVideoLink: (cfg?: AxiosConfig) => wrap(
      a.$post("/graphql", {
        query: `
          query {
            listMyTask1 {
              items {
                urlTask
              }
            }
          }
        `,
      }, { ...cfg }),
    ),

    setVideoLink: (link: string, cfg?: AxiosConfig) => wrap(
      a.$post("/graphql", {
        query: `
          mutation ($link: String) {
            createTask1(urlTask: $link)
          }
        `,
        variables: { link },
      }, { ...cfg }),
    ),

    findUser: (id: string, cfg?: AxiosConfig) => wrapGql(
      a.$post("/graphql", {
        query: `
          query ($id: UUID!) {
            findUser(id: $id) {
              id
              # oauthID
              firstName {
                current
              }
              lastName {
                current
              }
              patronymic {
                current
              }
            }
          }
        `,
        variables: { id },
      }, { ...cfg }),
      "findUser",
    ),

    findUserByBp: (bpID: string, statuses: string[] = [], cfg?: AxiosConfig) =>
      wrapGql(
        a.$post("/graphql", {
          query: `
          query ($bpID: String! $statuses: [String!]!) {
            findUserByBP(bpID: $bpID statuses: $statuses) {
              id
              firstName {
                current
              }
              lastName {
                current
              }
              patronymic {
                current
              }
            }
          }
        `,
          variables: { bpID, statuses },
        }, { ...cfg }),
        "findUserByBP",
      ),

    createDog: (variables: any, cfg?: AxiosConfig) => wrapGql(
      a.$post("/graphql", {
        query: `
          mutation (
            $secondAttendant: UUID
            $participants: [UUID]
            $program: String
            $disabled: Boolean
            $city: String
            $lastName: String
            $programHotel: Boolean
            $disabledCategory: String
            $transfer: String
            $transfer2: String
            $arrivalPlace: String
            $firstName: String
            $number1: String
            $patronymic: String
            $departurePlace: String
            $number3: String
            $departureDate: DateTime
            $departureTime: DateTime
            $telephone: String
            $arrivalDate: DateTime
            $arrivalTime: DateTime
          ) {
             createDog (
               secondAttendant: $secondAttendant
               participants: $participants
               program: $program
               disabled: $disabled
               city: $city
               lastName: $lastName
               programHotel: $programHotel
               disabledCategory: $disabledCategory
               transfer: $transfer
               transfer2: $transfer2
               arrivalPlace: $arrivalPlace
               firstName: $firstName
               number1: $number1
               patronymic: $patronymic
               departurePlace: $departurePlace
               number3: $number3
               departureDate: $departureDate
               departureTime: $departureTime
               telephone: $telephone
               arrivalDate: $arrivalDate
               arrivalTime: $arrivalTime
            )
          }
        `,
        variables,
      }, { ...cfg }),
      "createDog",
    ),

    getDog: (cfg?: AxiosConfig) => wrapGql(
      a.$post("/graphql", {
        query: `
          query {
            listMyDog {
              items {
                id
                creationTime
                updateTime
                author
                # secondAttendantBPID
                secondAttendant {
                  id
                  firstName {
                    current
                  }
                  lastName {
                    current
                  }
                  patronymic {
                    current
                  }
                }
                participants {
                  id
                  firstName {
                    current
                  }
                  lastName {
                    current
                  }
                  patronymic {
                    current
                  }
                }
                program
                state
                disabled
                city
                lastName
                programHotel
                disabledCategory
                transfer
                transfer2
                arrivalPlace
                dateparticipation
                firstName
                number1
                patronymic
                departurePlace
                number3
                departureDate
                departureTime
                telephone
                arrivalDate
                arrivalTime
                returnComment
              }
            }
          }
        `,
      }, { ...cfg }),
      "listMyDog",
    ),

    updateDog: (variables: any, cfg?: AxiosConfig) => wrapGql(
      a.$post("/graphql", {
        query: `
          mutation (
            $id: UUID
            $secondAttendant: UUID
            $participants: [UUID]
            $program: String
            $disabled: Boolean
            $city: String
            $lastName: String
            $programHotel: Boolean
            $disabledCategory: String
            $transfer: String
            $transfer2: String
            $arrivalPlace: String
            $firstName: String
            $number1: String
            $patronymic: String
            $departurePlace: String
            $number3: String
            $departureDate: DateTime
            $departureTime: DateTime
            $telephone: String
            $arrivalDate: DateTime
            $arrivalTime: DateTime
          ) {
             updateDog (
               id: $id
               secondAttendant: $secondAttendant
               participants: $participants
               program: $program
               disabled: $disabled
               city: $city
               lastName: $lastName
               programHotel: $programHotel
               disabledCategory: $disabledCategory
               transfer: $transfer
               transfer2: $transfer2
               arrivalPlace: $arrivalPlace
               firstName: $firstName
               number1: $number1
               patronymic: $patronymic
               departurePlace: $departurePlace
               number3: $number3
               departureDate: $departureDate
               departureTime: $departureTime
               telephone: $telephone
               arrivalDate: $arrivalDate
               arrivalTime: $arrivalTime
            )
          }
        `,
        variables,
      }, { ...cfg }),
      "updateDog",
    ),

    getDogAsParticipant: (cfg?: AxiosConfig) => wrapGql(
      a.$post("/graphql", {
        query: `
          query {
            listMyDogByParticipants {
              id
              bpID
              state
              lastName
              dateparticipation
              firstName
              patronymic
              departureDate
              departureTime
              telephone
              arrivalDate
              arrivalTime
              returnComment
              departureExpo
            }
          }
        `,
      }, { ...cfg }),
      "listMyDogByParticipants",
    ),

    createQuickUser: (variables: Record<string, any>, cfg?: AxiosConfig) =>
      wrapGql(
        a.$post("/graphql", {
          query: `
            mutation (
              $key: String
              $email: String
              $firstName: String
              $lastName: String
              $patronymic: String
              $status: UUID
              $sex: UUID
              $region: String
              $birthDate: DateTime
            ) {
              createQuickUser (
                key: $key
                email: $email
                firstName: $firstName
                lastName: $lastName
                patronymic: $patronymic
                status: $status
                sex: $sex
                region: $region
                birthDate: $birthDate
              )
            }
          `,
          variables,
        }, { ...cfg }),
        "createQuickUser",
      ),

    getTeams: () => gql(
      `
        query {
          listMyTeamByParticipants {
            id
            # creationTime
            # updateTime
            # author {
            #
            # }
            participants {
              firstName {
                current
              }
              lastName {
                current
              }
              patronymic {
                current
              }
            }
            # region
            teamName
            zayavki {
              id
              state
              category
              companyame
              region
              email
              phone
              sitelink
              cennost
              napravlenyiemer
              naimenovaniyeproectnoyactivnosty
              tsel
              zadachy
              datanachala
              datacontsa
              statusmaschtab
              geography
              format
              opisaniye
              obosnovanye
              rezultatopisaniye
              kolichestvouchastnikov
              categoryuchastnikov
              unikalnost
              organy
              rukovoditel
              projectteam
              opyt
              presentation { url }
              social
              docs { url }
              doc2 { url }
              doc21 { url }
              doc22 { url }
              doc23 { url }
              projectevents
              zakupki
              zakupkiaktivov
              oplatatruda
              komandirovki
              doc3 { url }
              doc4 { url }
              doc5 { url }
              doc6 { url }
              doc7 { url }
              summazapros
              summafinace
              summabudjet
            }
          }
        }
      `,
    ),

    getOcenka: (cfg?: AxiosConfig) =>
      wrapGql(
        a.$post("/graphql", {
          query: `
            query ($limit: Int!, $sorters: [Sorter!]!) {
              listMyOcenka (limit: $limit, sorters: $sorters) {
                items {
                  id
                  zayavka {
                    id
                    naimenovaniyeproectnoyactivnosty
                  }
                  question1
                  question2
                  question3
                  question4
                  question5
                  question6
                  question7
                  question8
                  question9
                  question10
                  approved
                  creationTime
                }
              }
            }
          `,
          variables: {
            limit: 1000,
            sorters: [{ name: "creationTime", order: "DESC" }],
          },
        }, { ...cfg }),
        "listMyOcenka",
      ),

    getOcenkaByZayavka: (id: String, cfg?: AxiosConfig) =>
      wrapGql(
        a.$post("/graphql", {
          query: `
            query ($zayavka: UUID) {
              listMyOcenkaByZayavka (zayavka: $zayavka) {
                question10
              }
            }
          `,
          variables: { zayavka: id },
        }, { ...cfg }),
        "listMyOcenkaByZayavka",
      ),

    updateOcenka: (variables: Record<string, any>, method: string, cfg?: AxiosConfig) =>
      wrapGql(
        a.$post("/graphql", {
          query: `
            mutation (
              $id: UUID
              $zayavka: UUID
              $question1: Float
              $question2: Float
              $question3: Float
              $question4: Float
              $question5: Float
              $question6: Float
              $question7: Float
              $question8: Float
              $question9: Float
              $question10: String
            ) {
              ${method}Ocenka (
                id: $id
                zayavka: $zayavka
                question1: $question1
                question2: $question2
                question3: $question3
                question4: $question4
                question5: $question5
                question6: $question6
                question7: $question7
                question8: $question8
                question9: $question9
                question10: $question10
              )
            }
          `,
          variables,
        }, { ...cfg }),
        "updateOcenka",
      ),
  }
}
