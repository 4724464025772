import { ApiFile } from "@/types/api/file"
import type { User } from "@/types/api/person"
import type { Message } from "@/types/api/chat"

export function changeUrl (url: string) {
  window.history.replaceState({}, "", url)
}

export const isUserAdmin = (user: User) => Boolean(user) && [
  "admin",
  "superadmin",
].some(r => user.roles.includes(r))

export function getFailToken (
  error: NuxtContext["error"], i18n: NuxtContext["i18n"],
) {
  return () => error({
    statusCode: 404, message: i18n.t("error.error") as string,
  })
}

export function onLoad (cb: Function, delay = 1) {
  const delayedCb = () => setTimeout(() => cb(), delay)
  if (document.readyState === "complete") delayedCb()
  else window.addEventListener("load", delayedCb, { once: true })
}

export function parseEvent (_host: string): string {
  return "grantsrddm"
  // const hostParts = host.split(".")
  // return hostParts[hostParts.length - 3] || "main"
}

export function parseFileName (path: ApiFile["path"]): string {
  if (!path) return ""
  const parts = path.split("/")
  return parts[parts.length - 1]
}

export function msgToStr (msg: Message): string {
  const { type, content } = msg
  // @ts-ignore
  if (type === "text") return content
  // @ts-ignore
  if (type === "file") return content.description || content.file
  return ""
}

export function cutId (id: string): string {
  return id.split("-").splice(0, 2).join("-")
}
