// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o9iu6Lun{align-items:center;border:1px solid transparent;border-radius:9999px;color:inherit;display:flex;flex-shrink:0;font-weight:500;justify-content:center;line-height:normal;overflow:hidden;padding:1.14em 1em;position:relative;transition:.1s linear;transition-property:background,padding;width:-moz-max-content;width:max-content}.fGTkyzwR{background:var(--color-primary);color:var(--color-bg)}@media(hover:hover){.fGTkyzwR:not(:disabled):hover{background:var(--color-primary-hover)}}@media(hover:none){.fGTkyzwR:not(:disabled):active{background:var(--color-primary-hover)}}.jACZpL82{background:var(--color-bg)}@media(hover:hover){.jACZpL82:not(:disabled):hover{background:#1a3fb5}}@media(hover:none){.jACZpL82:not(:disabled):active{background:#1a3fb5}}.AcvEDwrY{border-color:currentColor}@media(hover:hover){.AcvEDwrY:not(:disabled):hover{background:hsla(0,0%,100%,.1)}}@media(hover:none){.AcvEDwrY:not(:disabled):active{background:hsla(0,0%,100%,.1)}}@media(max-width:1229.98px){.ucNa2CL4{height:3em;padding:0;width:3em}.ucNa2CL4 .r7LbDzQd{display:none}}.c2TbfQsB{pointer-events:none}.c2TbfQsB>*{opacity:0}.c2TbfQsB ._8SaoDvzf{opacity:1}.c2TbfQsB .r7LbDzQd{margin-right:0!important}.r7LbDzQd:not(:only-child){margin-right:.71em}.nVcR3Yug{--size:1.71em;border-radius:9999px;overflow:hidden}._8SaoDvzf{left:50%;position:absolute!important;top:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"btn": "o9iu6Lun",
	"btnPrimary": "fGTkyzwR",
	"btnSecondary": "jACZpL82",
	"btnOutline": "AcvEDwrY",
	"btnSmallOnMobile": "ucNa2CL4",
	"text": "r7LbDzQd",
	"btnLoading": "c2TbfQsB",
	"spinner": "_8SaoDvzf",
	"icon": "nVcR3Yug"
};
module.exports = ___CSS_LOADER_EXPORT___;
