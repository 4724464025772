import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _29f8dfcd = () => interopDefault(import('../pages/user/index.ts' /* webpackChunkName: "pages/user/index" */))
const _1c75ff9a = () => interopDefault(import('../pages/auth/impersonalize.vue' /* webpackChunkName: "pages/auth/impersonalize" */))
const _45dfa2f9 = () => interopDefault(import('../pages/user/application/index.vue' /* webpackChunkName: "pages/user/application/index" */))
const _4bc7814f = () => interopDefault(import('../pages/user/feedback.vue' /* webpackChunkName: "pages/user/feedback" */))
const _06e0f57c = () => interopDefault(import('../pages/user/grant.vue' /* webpackChunkName: "pages/user/grant" */))
const _965279dc = () => interopDefault(import('../pages/user/profile/index.vue' /* webpackChunkName: "pages/user/profile/index" */))
const _808155b2 = () => interopDefault(import('../pages/user/team.vue' /* webpackChunkName: "pages/user/team" */))
const _2a6fc7db = () => interopDefault(import('../pages/auth/oauth/_provider.vue' /* webpackChunkName: "pages/auth/oauth/_provider" */))
const _54bc0fa1 = () => interopDefault(import('../pages/user/application/_id.vue' /* webpackChunkName: "pages/user/application/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ru",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/user",
    component: _29f8dfcd,
    name: "user___ru___default"
  }, {
    path: "/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru___default"
  }, {
    path: "/ru/user",
    component: _29f8dfcd,
    name: "user___ru"
  }, {
    path: "/user/application",
    component: _45dfa2f9,
    name: "user-application___ru___default"
  }, {
    path: "/user/feedback",
    component: _4bc7814f,
    name: "user-feedback___ru___default"
  }, {
    path: "/user/grant",
    component: _06e0f57c,
    name: "user-grant___ru___default"
  }, {
    path: "/user/profile",
    component: _965279dc,
    name: "user-profile___ru___default"
  }, {
    path: "/user/team",
    component: _808155b2,
    name: "user-team___ru___default"
  }, {
    path: "/ru/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru"
  }, {
    path: "/ru/user/application",
    component: _45dfa2f9,
    name: "user-application___ru"
  }, {
    path: "/ru/user/feedback",
    component: _4bc7814f,
    name: "user-feedback___ru"
  }, {
    path: "/ru/user/grant",
    component: _06e0f57c,
    name: "user-grant___ru"
  }, {
    path: "/ru/user/profile",
    component: _965279dc,
    name: "user-profile___ru"
  }, {
    path: "/ru/user/team",
    component: _808155b2,
    name: "user-team___ru"
  }, {
    path: "/ru/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru"
  }, {
    path: "/ru/user/application/:id",
    component: _54bc0fa1,
    name: "user-application-id___ru"
  }, {
    path: "/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru___default"
  }, {
    path: "/user/application/:id",
    component: _54bc0fa1,
    name: "user-application-id___ru___default"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___ru___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
