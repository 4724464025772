
import Vue from "vue"

export default Vue.extend({
  data: () => ({
    phones: [
      "8 800 333 63 11",
      "8 495 565 30 10",
    ],
    emails: [
      "zabota@pervye.ru",
    ],
    links: {
      "Политика в отношении обработки персональных данных": "https://xn--90acagbhgpca7c8c7f.xn--p1ai/files/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%A0%D0%94%D0%94%D0%9C.pdf",
      "Вопрос-ответ": "https://xn--b1agzqc8c.xn--90acagbhgpca7c8c7f.xn--p1ai/?group=abc",
      Контакты: "https://xn--90acagbhgpca7c8c7f.xn--p1ai/contacts",
    },
  }),
})
